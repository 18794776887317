<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                
                <div class="card bgCustom">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-3">
                                <v-select v-model="officeModel" :items="officeLists" default="" item-value="dept_id" item-text="descr_depart" label="Select Office" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-select v-model="userModel" :items="userLists" default="" item-value="user_id" item-text="user_id" label="Select User" clearable dense prepend-icon="mdi-account"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-dialog ref="dialog" v-model="modalDateFrom" :return-value.sync="dateFrom" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateFrom" label="Start Period" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateFrom" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateFrom = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog.save(dateFrom)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-3">
                                <v-dialog ref="dialog2" v-model="modalDateTo" :return-value.sync="dateTo" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateTo" label="End Period" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateTo" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateTo = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog2.save(dateTo)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-3">
                                <v-select v-model="statModel" :items="statLists" default="" item-value="value" item-text="text" label="Select PR Status" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-select v-model="rawMatModel" :items="rawMatLists" default="" item-value="value" item-text="text" label="Raw Mat. / General" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-6">
                                <v-btn class="mr-4" color="primary" elevation="2" small rounded @click="getChartData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-12 cardGraph1">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div id="chartContainer1" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="modal fade modal-xl" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true">
                <div class="modal-dialog" style="max-width: 80%;">
                    <div class="modal-content bgCustom3">
                    <div class="modal-header">
                        <h5 class="modal-title" id="detailModalLabel">Detail of {{ detailModalTitle }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                                
                            <v-data-table :headers="headers" :items="itemDetailLists" class="elevation-1" page-count="5" :search="search" :loading="loadingDetail" dense>
                                <template v-slot:top>
                                    <v-toolbar flat color="white">
                                        <div class="d-flex w-25">
                                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                        </div>
                                    </v-toolbar>
                                </template>
                            </v-data-table>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    
</template>

<script>
import { fa } from 'vuetify/lib/locale';


export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'GBRK',
                disabled: false,
                href: '/admin/gbrk',
                },
                {
                text: 'GBRK Analysis',
                disabled: false,
                href: '#',
                },
                {
                text: 'Purchase Analysis',
                disabled: true,
                href: '#',
                },
            ],
            officeModel: '',
            officeLists: [],
            userModel: '',
            userLists: [],
            dateFrom: '',
            modalDateFrom: false,
            dateTo: '',
            modalDateTo: false,
            statModel: '',
            statLists: [
                {
                    value: '',
                    text: 'ALL'
                },
                {
                    value: 'O',
                    text: 'OPEN'
                },
                {
                    value: 'C',
                    text: 'CLOSED'
                }
            ],
            rawMatModel: '',
            rawMatLists: [
                {
                    value: 'General',
                    text: 'GENERAL'
                },
                {
                    value: 'Rawmat',
                    text: 'RAW MATS.'
                }
            ],
            dateFromFormatted: '',
            dateToFormatted: '',
            detailModalTitle: '',
            headers: [],
            itemDetailLists: [],
            loadingDetail: false,
            search: ''
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){

            document.querySelector(".cardGraph1").style.display = "none";
            this.$store.dispatch('setOverlay', true)

            this.dateFrom = this.currentPeriod()
            this.dateTo = this.currentPeriod()

            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/PurchaseAnalysis`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                this.$store.dispatch('setOverlay', false)
                this.officeLists = res.data.office
                this.userLists = res.data.user
            })
        },

        async getChartData(){

            if(this.dateFrom == null || this.dateFrom == '' || this.dateTo == null || this.dateTo == ''  ){
                Swal.fire({
                        text: 'Period is a must!',
                        icon: 'error',
                    })
                return false
            }

            document.querySelector(".cardGraph1").style.display = "none";
            this.$store.dispatch('setOverlay', true)

            if (this.dateFrom) {
                this.dateFromFormatted = new Date(this.dateFrom).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.dateFromFormatted = ''
            }

            if (this.dateTo) {
                this.dateToFormatted = new Date(this.dateTo).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.dateToFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/PurchaseAnalysis/getChartData`, { 

                    office: this.officeModel ? this.officeModel : '',
                    user: this.userModel ? this.userModel : '',
                    prStat: this.statModel ? this.statModel : '',
                    rawMat: this.rawMatModel ? this.rawMatModel : '',
                    dateFrom: this.dateFromFormatted,
                    dateTo: this.dateToFormatted,

                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                if(res.data.arr_list1.length != 0){
                    this.$store.dispatch('setOverlay', false)
                    document.querySelector(".cardGraph1").style.display = "block";
                    this.renderChart1(res.data.arr_list1, res.data.arr_list2, res.data.arr_list3, res.data.arr_list4)
                } else {
                    document.querySelector(".cardGraph1").style.display = "none";
                    this.$store.dispatch('setOverlay', false)
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'error',
                    })
                }
            })

        },

        renderChart1(data1, data2, data3, data4){

            var chart1 = new CanvasJS.Chart("chartContainer1", {
                animationEnabled: true,
                theme: "light2",
                exportEnabled: false,
                title: {
                    text: "Chart Purchase Analysis",
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "DAYS'",
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: "column",
                        click: this.PR_APRV,
                        showInLegend: true,
                        name: "PR->APRV",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        dataPoints: data1
                    },
                    {
                        type: "column",
                        click: this.APRV_PO,
                        showInLegend: true,
                        name: "APRV->PO",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        dataPoints: data2
                    },
                    {
                        type: "column",
                        click: this.PO_RCV,
                        showInLegend: true,
                        name: "PO->RCV",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        dataPoints: data3
                    },
                    {
                        type: "column",
                        click: this.PR_RCV,
                        showInLegend: true,
                        name: "PR->RCV",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        dataPoints: data4
                    }
                ]
            })

            chart1.render()

        },

        async PR_APRV(e){

            $('#detailModal').modal('show')
            this.loadingDetail = true

            this.itemDetailLists = []
            this.detailModalTitle = 'Purchase to Approve Periode ' + e.dataPoint.label

            this.headers = [
                { text: 'PERIODE', value: 'period', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PRU ID', value: 'pru_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DATE PR', value: 'dt_pru', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DATE APRV', value: 'dt_aprv', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DAY INTERVAL', value: 'DayInterval', align: 'right', class: 'primary--text blue lighten-5' }
            ]

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/PurchaseAnalysis/getPurchaseAnalysisDetail`, { 
                
                    type: 'PR_APRV',
                    period: e.dataPoint.label,
                    office: this.officeModel ? this.officeModel : '',
                    users: this.usersModel ? this.usersModel : '',
                    pr_stat: this.pr_statModel ? this.pr_statModel : '',
                    rawMat: this.rawMatModel ? this.rawMatModel : ''
                    
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.loadingDetail = false
                this.itemDetailLists = res.data.result

            })

        },

        async APRV_PO(e){

            $('#detailModal').modal('show')
            this.loadingDetail = true

            this.itemDetailLists = []
            this.detailModalTitle = 'Approve to Purchase Order Periode ' + e.dataPoint.label

            this.headers = [
                { text: 'PERIODE', value: 'period', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PRU ID', value: 'pru_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PO ID', value: 'po_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PRU ITEM', value: 'pru_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DESCR', value: 'descr', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DATE APRV', value: 'dt_aprv', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DATE PO', value: 'dt_po', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DAY INTERVAL', value: 'DayInterval', align: 'right', class: 'primary--text blue lighten-5' }
            ]

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/PurchaseAnalysis/getPurchaseAnalysisDetail`, { 
                
                    type: 'APRV_PO',
                    period: e.dataPoint.label,
                    office: this.officeModel ? this.officeModel : '',
                    users: this.usersModel ? this.usersModel : '',
                    pr_stat: this.pr_statModel ? this.pr_statModel : '',
                    rawMat: this.rawMatModel ? this.rawMatModel : ''
                    
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.loadingDetail = false
                this.itemDetailLists = res.data.result

            })

        },

        async PO_RCV(e){

            $('#detailModal').modal('show')
            this.loadingDetail = true

            this.itemDetailLists = []
            this.detailModalTitle = 'Purchase Order to Receive Periode ' + e.dataPoint.label

            this.headers = [
                { text: 'PERIODE', value: 'period', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PRU ID', value: 'pru_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PO ID', value: 'po_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PRU ITEM', value: 'pru_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DESCR', value: 'descr', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DATE PO', value: 'dt_po', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DATE RCV', value: 'dt_rcv', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DAY INTERVAL', value: 'DayInterval', align: 'right', class: 'primary--text blue lighten-5' }
            ]

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/PurchaseAnalysis/getPurchaseAnalysisDetail`, { 
                
                    type: 'PO_RCV',
                    period: e.dataPoint.label,
                    office: this.officeModel ? this.officeModel : '',
                    users: this.usersModel ? this.usersModel : '',
                    pr_stat: this.pr_statModel ? this.pr_statModel : '',
                    rawMat: this.rawMatModel ? this.rawMatModel : ''
                    
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.loadingDetail = false
                this.itemDetailLists = res.data.result

            })

        },

        async PR_RCV(e){

            $('#detailModal').modal('show')
            this.loadingDetail = true

            this.itemDetailLists = []
            this.detailModalTitle = 'Purchase Request to Receive Periode ' + e.dataPoint.label

            this.headers = [
                { text: 'PERIODE', value: 'period', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PRU ID', value: 'pru_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PO ID', value: 'po_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PRU ITEM', value: 'pru_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DESCR', value: 'descr', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DATE PR', value: 'dt_pru', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DATE RCV', value: 'dt_rcv', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DAY INTERVAL', value: 'DayInterval', align: 'right', class: 'primary--text blue lighten-5' }
            ]

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/PurchaseAnalysis/getPurchaseAnalysisDetail`, { 
                
                    type: 'PR_RCV',
                    period: e.dataPoint.label,
                    office: this.officeModel ? this.officeModel : '',
                    users: this.usersModel ? this.usersModel : '',
                    pr_stat: this.pr_statModel ? this.pr_statModel : '',
                    rawMat: this.rawMatModel ? this.rawMatModel : ''
                    
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.loadingDetail = false
                this.itemDetailLists = res.data.result

            })

        },

        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },

        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },

        currentPeriod() {
            const current = new Date();
            current.setDate(current.getDate() + 20);

            const date = current.getFullYear() + '-' + ('0' + (current.getMonth())).slice(-2);      
            return date;
        }

    }
    
}
</script>

<style scoped>

    .v-application p {
        margin-bottom: 0px !important;
    }

    .v-text-field {
        padding-top: 0px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1rem;
    }

    .bgCustom {
        background: linear-gradient(to right, #fff, #f1f1f1) !important;
        color: #fff;
    }

    .bgCustom2 {
        background: #bde0fe !important;
        color: #fff;
    }

    .bgCustom3 {
        background: rgba(19, 64, 116, 0.5);
        color: #fff;
    }

    .container{
        margin-bottom: 60px !important;
    }

</style>